/* eslint-disable react-hooks/rules-of-hooks */
import { useTranslation } from 'react-i18next';
import Noty from '@root/utils/Noty';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AuthUser, AuthUserUpdate, updateAuthUser } from './auth';
import {
    ServiceConsoleData,
    ServiceIpResource,
    ServiceOsResource,
    ServiceProccess,
    ServiceState,
    ServiceUpdateData,
    singleLoad,
    singleUpdate,
    singleUpdateResources,
    singleUpdateStateProccess,
} from './services';
import { AppDispatch, RootState } from './types';
import useSubscribe from '@root/plugins/socket/useSubscribe';
import { cloneElement, FC, isValidElement } from 'react';

type proccessState = 'created' | 'proccessing' | 'completed' | 'failed';

type ClientBalanceNoty = {
    type: 'balance_update';
    payload: AuthUserUpdate;
};

type ClientServiceChangeStateNoty = {
    type: 'server_change_state';
    payload: ServiceUpdateData;
};

type ClientServiceCreatedNoty = {
    type: 'server_created';
    payload: {
        orderNo?: string;
        stateProccess: ServiceProccess | null;
        state?: ServiceState;
        ipRes: ServiceIpResource;
        expireDate: string;
    };
};

type ClientServiceOsChangeNoty = {
    type: 'server_change_os_state';
    payload: {
        orderNo?: string;
        stateProccess: ServiceProccess | null;
        data?: ServiceOsResource;
    };
};

type ClientServiceResourceChangeNoty = {
    type: 'server_change_resources_state';
    payload: {
        orderNo?: string;
        stateProccess: ServiceProccess | null;
        data?: ServiceOsResource;
    };
};

type ClientServiceConsoleStateNoty = {
    type: 'server_console_state';
    payload: {
        orderNo?: string;
        state: proccessState;
    } & ServiceConsoleData;
};

type ClientServiceEventStateNoty = {
    type: 'server_event';
    payload: {
        orderNo?: string;
    };
};

type ClientNoty = ClientBalanceNoty;
type ClientServiceNoty =
    | ClientServiceChangeStateNoty
    | ClientServiceOsChangeNoty
    | ClientServiceResourceChangeNoty
    | ClientServiceCreatedNoty
    | ClientServiceConsoleStateNoty
    | ClientServiceEventStateNoty;

const useInitializeSocketStore = (): void => {
    const { t } = useTranslation('uiNoty');
    const user = useSelector<RootState, AuthUser | null>(
        (_state) => _state.auth.user,
        shallowEqual,
    );
    const dispatch = useDispatch<AppDispatch>();

    useSubscribe<ClientNoty>(user ? `client:${user.id}` : undefined, (data) => {
        switch (data.type) {
            case 'balance_update': {
                dispatch(updateAuthUser(data.payload));
                Noty({
                    title: t('noty.balanceUpdate.title', {
                        defaultValue: 'Balansas',
                    }),
                    message: t('noty.balanceUpdate.message', {
                        defaultValue: 'Balansas papildytas',
                    }),
                });
                break;
            }
            // eslint-disable-next-line no-empty
            default: {
            }
        }
    });

    useSubscribe<ClientServiceNoty>(
        user ? `client-services:${user.id}` : undefined,
        (data) => {
            switch (data.type) {
                case 'server_event': {
                    if (typeof data.payload.orderNo !== 'undefined') {
                        dispatch(singleLoad(data.payload.orderNo));
                    }
                    break;
                }
                case 'server_change_state': {
                    if (typeof data.payload.orderNo !== 'undefined') {
                        dispatch(
                            singleUpdate(data.payload.orderNo, data.payload),
                        );
                    }
                    break;
                }
                case 'server_change_os_state': {
                    if (
                        typeof data.payload.orderNo !== 'undefined' &&
                        typeof data.payload.stateProccess !== 'undefined' &&
                        data.payload.stateProccess !== null
                    ) {
                        dispatch(
                            singleUpdateStateProccess(
                                data.payload.orderNo,
                                data.payload.stateProccess,
                            ),
                        );
                    } else if (
                        typeof data.payload.orderNo !== 'undefined' &&
                        typeof data.payload.data !== 'undefined'
                    ) {
                        dispatch(
                            singleUpdateResources(data.payload.orderNo, [
                                data.payload.data,
                            ]),
                        );
                        dispatch(
                            singleUpdateStateProccess(
                                data.payload.orderNo,
                                undefined,
                            ),
                        );
                    }
                    break;
                }
                case 'server_change_resources_state': {
                    break;
                }
                case 'server_created': {
                    if (typeof data.payload.orderNo !== 'undefined') {
                        // TODO: fix it. make it smoother, only that need needs to update.
                        dispatch(singleLoad(data.payload.orderNo));
                        /*
                        dispatch(
                            singleUpdateResources(
                                data.payload.orderNo,
                                [data.payload.ipRes],
                            ),
                        );
                        dispatch(
                            singleUpdate(data.payload.orderNo, {
                                state: data.payload.state,
                                expireDate: data.payload.expireDate,
                                stateProccess:
                                    data.payload.stateProccess !==
                                    null
                                        ? data.payload.stateProccess
                                        : undefined,
                            }),
                        );
                        */
                    }
                    break;
                }
                case 'server_console_state': {
                    if (
                        typeof data.payload.orderNo !== 'undefined' &&
                        data.payload.state === 'completed'
                    ) {
                        dispatch(
                            singleUpdate(data.payload.orderNo, {
                                console: {
                                    host:
                                        data.payload.host ||
                                        'vps001.testweb.vpsnet.com',
                                    port: data.payload.port,
                                    password: data.payload.password,
                                },
                            }),
                        );
                    }
                    break;
                }
                // eslint-disable-next-line no-empty
                default: {
                }
            }
        },
    );
};

const InitializeSocketStore: FC = ({ children }) => {
    useInitializeSocketStore();
    if (!isValidElement(children)) return null;
    return cloneElement(children);
};

export default InitializeSocketStore;
