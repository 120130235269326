import { PageComponent } from '@root/pages/Type';
import {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useState,
} from 'react';

export type LayoutContextState = {
    sidebarVisible: boolean;
    setSidebarVisible: Dispatch<SetStateAction<boolean>>;
};

const LayoutContext = createContext<LayoutContextState | null>(null);

export const useLayout = (): LayoutContextState => {
    const ctx = useContext(LayoutContext);
    if (ctx === null) {
        throw new Error(
            'useLayout must be inside a LayoutProvider with a value',
        );
    }
    return ctx;
};

export const LayoutProvider: PageComponent = ({ children }) => {
    const [sidebarVisible, setSidebarVisible] = useState(false);
    return (
        <LayoutContext.Provider
            value={{
                setSidebarVisible,
                sidebarVisible,
            }}
        >
            <>{children}</>
        </LayoutContext.Provider>
    );
};

export default LayoutContext;
