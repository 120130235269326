import { RootState } from '@root/store/types';
// import Script from 'next/script';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

const GoogleAnalytics = ({ ga_id }: { ga_id: string }) => {
    const auth = useSelector((state: RootState) => state.auth.user?.id);
    const router = useRouter();
    useEffect(() => {
        ReactGA.initialize(ga_id, {});

        const handleRouteChange = (url) => {
            ReactGA.send({ hitType: 'pageview', page: url });
        };

        // Initialize page tracking for the initial page load
        ReactGA.send({ hitType: 'pageview', page: router.asPath });

        // Listen for route changes and track pageviews
        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);
    useEffect(() => {
        if (auth) {
            ReactGA.set({ userId: auth });
        }
    }, [auth]);
    return null;
    // return (
    //     <>
    //         <Script
    //             async
    //             src={`https://www.googletagmanager.com/gtag/js?id=${ga_id}`}
    //         ></Script>
    //         <Script
    //             id="google-analytics"
    //             dangerouslySetInnerHTML={{
    //                 __html: `
    //       window.dataLayer = window.dataLayer || [];
    //       function gtag(){dataLayer.push(arguments);}
    //       gtag('js', new Date());

    //       gtag('config', '${ga_id}', {
    //         ${auth ? `'user_id': ${auth}` : ''}
    //       });
    //     `,
    //             }}
    //         ></Script>
    //     </>
    // );
};

export default GoogleAnalytics;
