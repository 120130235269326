import { useContext } from 'react';
import SocketContext from './SocketContext';
import { SocketContextType } from './types';

const useSocket = (): SocketContextType => {
    const context = useContext(SocketContext);
    if (context === undefined) {
        throw new Error('useSocket must be used within a SocketProvider');
    }
    return context;
};

export default useSocket;
