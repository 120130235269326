import { io, Socket } from 'socket.io-client';
import getConfig from 'next/config';
import { FC, useCallback, useEffect, useState } from 'react';
import { RootState } from '@root/store/types';
import { useSelector } from 'react-redux';
import SocketContext from './SocketContext';
import {
    Rooms,
    SocketContextType,
    SocketSubscribe,
    SocketUnsubscribe,
} from './types';
import _ from 'lodash';

const randomResName = () => Math.random().toString(36).substring(7);

const SocketProvider: FC = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [roomsNames, setRoomsNames] = useState<Array<string>>([]);
    const [rooms, setRooms] = useState<Rooms>({});
    const [connected, setConnected] = useState(false);

    const { publicRuntimeConfig } = getConfig() || {
        publicRuntimeConfig: { NOTY_URL: '' },
    };

    const token = useSelector((state: RootState) => state.auth.token);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        // console.log('[mounted]');

        // connect to Socket.io server
        const s = io(publicRuntimeConfig.NOTY_URL!, {
            query: {
                token: token || '',
            },
            reconnection: true,
            transports: ['websocket', 'polling'],
        });
        setSocket(s);

        /**
         * Connected
         */
        function connected() {
            // console.log('[received] connected');
            setConnected(true);
        }

        s.on('connect', connected);

        const cleanup = () => {
            // console.log('[unmounted]');
            if (s) {
                s.off('connect', connected);
                if (s.connected) {
                    s.disconnect();
                }
            }
        };

        return cleanup;
    }, []);

    useEffect(() => {
        if (!socket) return;

        function reconnect() {
            // console.log('[received] reconnect');
            setSocket(socket);
            setConnected(true);
        }

        /**
         * connection lost
         */
        function disconnect() {
            // console.log('[received] disconnect');
            setSocket(null);
            setConnected(false);
        }

        socket.on('disconnect', disconnect);
        socket.on('reconnect', reconnect);
        return () => {
            socket.off('disconnect', disconnect);
            socket.off('reconnect', reconnect);
        };
    }, [socket]);

    useEffect(() => {
        // console.log('rooms', roomsNames);
        if (!socket) return;
        // console.log('rooms connect', roomsNames);
        for (const room of roomsNames) {
            socket.on(room, rooms[room]);
        }
        const cleanup = () => {
            // console.log('rooms cleanuo', roomsNames);
            for (const room of roomsNames) {
                socket.off(room, rooms[room]);
            }
        };
        return cleanup;
    }, [roomsNames]);

    const subscribe: SocketSubscribe = useCallback(
        (room, callback) => {
            return new Promise((resolve, reject) => {
                if (!socket) {
                    return reject();
                }
                const resName = `response${randomResName()}`;
                socket.once(resName, (result: number) => {
                    if (result === 1) {
                        setRooms((_rooms) => ({
                            ..._rooms,
                            [room]: callback as any,
                        }));
                        setRoomsNames((_rooms) => [..._rooms, room]);
                        resolve(true);
                    } else {
                        reject();
                    }
                });
                socket.emit('subscribe', {
                    room,
                    resName,
                });
            });
        },
        [socket?.connected],
    );

    const unsubscribe: SocketUnsubscribe = useCallback(
        (room) => {
            return new Promise((resolve, reject) => {
                if (!socket) {
                    return reject();
                }
                const resName = `response${randomResName()}`;
                socket.once(resName, (result: number) => {
                    if (result === 1) {
                        setRooms((_restRooms) => {
                            const { [room]: roomKey, ...restRooms } = _restRooms;
                            return restRooms;
                        });
                        setRoomsNames((_rooms) =>
                            _.remove(_rooms, (_room) => _room == room),
                        );
                        socket.off(room);
                        resolve(true);
                    } else {
                        reject();
                    }
                });
                socket.emit('unsubscribe', {
                    room,
                    resName,
                });
            });
        },
        [socket?.connected],
    );

    const data: SocketContextType = Object.freeze({
        socket,
        subscribe,
        unsubscribe,
        connected,
    });

    return (
        <SocketContext.Provider value={data}>{children}</SocketContext.Provider>
    );
};

export default SocketProvider;
