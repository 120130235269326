import { PageComponent } from '@root/pages/Type';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

type LiveHelpContextType = {
    wantMoveUp: (trully: boolean) => void;
    wantHide: (trully: boolean) => void;
    needToMoveUp: boolean;
    needToHide: boolean;
};

const LiveHelpContext = createContext<LiveHelpContextType | undefined>(
    undefined,
);

export const useLiveHelp = (): LiveHelpContextType => {
    const context = useContext(LiveHelpContext);
    if (context === undefined) {
        throw new Error('useLiveHelp must be used within a LiveHelpProvider');
    }
    return context;
};

export const useForceUpLiveHelp = () => {
    const { wantMoveUp } = useLiveHelp();
    useEffect(() => {
        wantMoveUp(true);
        return () => {
            wantMoveUp(false);
        };
    }, []);
};

export const LiveHelpProvider: PageComponent = ({ children }) => {
    const [needToMoveUp, setNeedToMoveUp] = useState(0);
    const [needToHide, setNeedToHide] = useState(0);
    const wantMoveUp = useCallback((trully: boolean) => {
        setNeedToMoveUp((_needToMoveUp) =>
            trully ? _needToMoveUp + 1 : _needToMoveUp - 1,
        );
    }, []);
    const wantHide = useCallback((trully: boolean) => {
        setNeedToHide((_needToHide) =>
            trully ? _needToHide + 1 : _needToHide - 1,
        );
    }, []);
    return (
        <LiveHelpContext.Provider
            value={{
                wantMoveUp,
                wantHide,
                needToMoveUp: needToMoveUp > 0,
                needToHide: needToHide > 0,
            }}
        >
            {children}
        </LiveHelpContext.Provider>
    );
};

export default LiveHelpContext;
