// TOOD: Add image alt tags.
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { connect } from 'react-redux';
import { userLogout } from '@store/auth/actions';
import { AppDispatch, RootState } from '@root/store/types';
import { useRouter } from 'next/router';
import Dropdown from 'react-bootstrap/Dropdown';
import { Trans } from 'react-i18next';
import { Router as i18nRouter } from '@root/router';
import { forwardRef, useEffect } from 'react';
import { setCookie } from '@root/utils/cookie';
import { setLanguage } from '@root/store/i18n';
import { AuthUser } from '@root/store/auth';
import classnames from 'classnames';
import styles from './Navbar.module.scss';
import Link from '../Link';
import { useLayout } from '../layouts/LayoutProvider';
import Logo from '@static/img/logos/logo_icon_dark.svg';
import UserSvg from '@static/img/user.svg';
import MobileMenu from '@static/img/icons/mobile-menu.svg';
import CloseIcon from '@static/img/icons/close.svg';
import Flags from 'country-flag-icons/react/3x2';
import { useMediaQuery } from '@react-hook/media-query';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';
import { PageComponent } from '@root/pages/Type';

const languages = [
    {
        short: 'lt',
        value: 'Lietuvių',
        icon: () => <Flags.LT title="Lietuvių" />,
    },
    {
        short: 'en',
        value: 'English',
        icon: () => <Flags.US title="English" />,
    },
    /*
    {
        short: 'ru',
        value: 'Русский',
        icon: 'ru',
    },
    */
];

const NavDropdownToggle = forwardRef<HTMLLIElement, unknown>(
    ({ children }, ref) => (
        <li
            className={`nav-item dropdown ${styles.dropdown} ${styles.nav_lang}`}
            ref={ref}
        >
            {children}
        </li>
    ),
);

NavDropdownToggle.displayName = 'NavDropdownToggle';

type NavToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
};

const NavToggle = forwardRef<HTMLAnchorElement, NavToggleProps>(
    ({ children, onClick }, ref) => (
        <a
            className={`nav-link dropdown-toggle ${styles.nav_lang_item}`}
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                if (onClick) onClick(e);
            }}
        >
            {children}
        </a>
    ),
);
NavToggle.displayName = 'NavToggle';

const NavUserDropdownToggle = forwardRef<HTMLDivElement, unknown>(
    ({ children }, ref) => (
        <div className={`dropdown ml-auto mr-4 ${styles.dropdown}`} ref={ref}>
            {children}
        </div>
    ),
);
NavUserDropdownToggle.displayName = 'NavUserDropdownToggle';

type NavUserToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {};
};

const NavUserToggle = forwardRef<HTMLDivElement, NavUserToggleProps>(
    ({ children, onClick }, ref) => (
        <div
            className={styles.user_media}
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                if (onClick) onClick(e);
            }}
        >
            {children}
        </div>
    ),
);

NavUserToggle.displayName = 'NavUserToggle';

type NavLinkItemProps = {
    href: string;
    children?: React.ReactNode;
};

const NavLinkItem = forwardRef<HTMLAnchorElement, NavLinkItemProps>(
    ({ children, href }, ref) => (
        <Link href={href}>
            <a className="dropdown-item" ref={ref}>
                {children}
            </a>
        </Link>
    ),
);
NavLinkItem.displayName = 'NavLinkItem';

type NavItemProps  = DropdownItemProps & {
    className?: string;
    children?: React.ReactNode;
};

const NavItem = forwardRef<HTMLAnchorElement, NavItemProps>(
    ({ children, className, onClick }, ref) => (
        <a
            href="#"
            className={classnames('dropdown-item', className)}
            ref={ref}
            onClick={onClick}
        >
            {children}
        </a>
    ),
);
NavItem.displayName = 'NavItem';

type NavbarProps = {
    currentLanguage: string | null;
    user: AuthUser | null;
    handleUserLogout: () => void;
    handleSetLanguage: (language: string) => void;
};

const Navbar: PageComponent<NavbarProps> = ({
    currentLanguage,
    user,
    handleUserLogout,
    handleSetLanguage,
}) => {
    const matches = useMediaQuery('only screen and (max-width: 991px)');
    const router = useRouter();
    const { sidebarVisible, setSidebarVisible } = useLayout();

    useEffect(() => {
        i18nRouter.prefetchRoute('landing_home', currentLanguage);
    }, []);

    // TODO: "smooth" logout.
    if (!user || !currentLanguage) {
        return null;
    }
    const { email, id: userId, balance } = user;

    const handleLanguageOnClick = async (lang: string) => {
        if (
            languages.find((language) => language.short === lang) === undefined
        ) {
            return;
        }
        setCookie('next-i18next', lang);
        handleSetLanguage(lang);
        const params = { ...router.query };
        const name = params.nextRoute;
        delete params.nextRoute;
        await i18nRouter.pushRoute(name, params, lang, {
            locale: lang,
        });
    };

    const onLogout = (event) => {
        event.preventDefault();
        i18nRouter.pushRoute('landing_home', {}, currentLanguage, {
            locale: currentLanguage,
        });
        handleUserLogout();
    };

    return (
        <>
            <nav
                className={`navbar navbar-expand-lg navbar-light ${styles.navbar}`}
            >
                <Link href="ui_services_list">
                    <a className={`navbar-brand ${styles.navbar_brand}`}>
                        <Logo />
                    </a>
                </Link>

                <div
                    className={`collapse navbar-collapse ${styles.navbar_collapse}`}
                    id="navbarNav"
                >
                    <ul className={`navbar-nav ml-auto ${styles.navbar_nav}`}>
                        <Dropdown as={NavDropdownToggle}>
                            <Dropdown.Toggle as={NavToggle} id="nav_lang">
                                {currentLanguage.toUpperCase()}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {languages
                                    .filter(
                                        (language) =>
                                            language.short !==
                                            currentLanguage.toLowerCase(),
                                    )
                                    .map((language) => (
                                        <Dropdown.Item
                                            key={`select-language-${language.short}`}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                handleLanguageOnClick(
                                                    language.short,
                                                );
                                            }}
                                            className={styles.langItem}
                                        >
                                            <span className={`${styles.flag}`}>
                                                {language.icon()}
                                            </span>
                                            {language.value}
                                        </Dropdown.Item>
                                    ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </ul>
                    <span className={styles.navbar_text}>
                        {balance.toFixed(2)} €
                    </span>
                    <Link href="ui_payments_topup">
                        <a className={`btn btn-outline-primary ${styles.btn}`}>
                            <span>
                                <Trans i18nKey="topup" ns="uiNavigation">
                                    Papildyti
                                </Trans>
                            </span>
                        </a>
                    </Link>
                </div>
                <Dropdown as={NavUserDropdownToggle} alignRight>
                    <Dropdown.Toggle as={NavUserToggle} id="nav_user">
                        <div className={styles.media_body}>
                            <div
                                className={classnames(styles.login_name, {
                                    [styles.blur]: email.length > 20,
                                })}
                            >
                                {email.substring(0, 20)}
                            </div>
                            <Trans
                                i18nKey="user.id"
                                ns="uiNavigation"
                                values={{ id: userId }}
                            >
                                ID: [[id]]
                            </Trans>
                        </div>
                        <UserSvg />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        className={classnames(
                            styles.dropdown_menu,
                            styles.dropdown,
                            styles.nav_lang,
                        )}
                    >
                        {matches && (
                            <>
                                <span className={classnames("dropdown-item", styles.navbar_text, styles.navbar_text_xs)}>
                                    {balance.toFixed(2)} €
                                </span>
                            </>
                        )}
                        {matches && (
                            <Dropdown.Item
                                as={NavLinkItem}
                                href="ui_payments_topup"
                            >
                                <Trans i18nKey="topup" ns="uiNavigation">
                                    Papildyti
                                </Trans>
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item
                            as={NavLinkItem}
                            href="ui_account_profile"
                        >
                            <Trans
                                i18nKey="user.items.account"
                                ns="uiNavigation"
                            >
                                Mano paskyra
                            </Trans>
                        </Dropdown.Item>
                        {matches &&
                            languages
                                .filter(
                                    (language) =>
                                        language.short !==
                                        currentLanguage.toLowerCase(),
                                )
                                .map((language) => (
                                    <Dropdown.Item
                                        as={NavItem}
                                        key={`select-language-${language.short}`}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleLanguageOnClick(
                                                language.short,
                                            );
                                        }}
                                        className={styles.langItem}
                                    >
                                        <span className={`${styles.flag}`}>
                                            {language.icon()}
                                        </span>
                                        {language.value}
                                    </Dropdown.Item>
                                ))}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={onLogout}>
                            <Trans
                                i18nKey="user.items.logout"
                                ns="uiNavigation"
                            >
                                Atsijungti
                            </Trans>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <button
                    className={classnames(
                        'navbar-toggler',
                        styles.navbar_toggler,
                        {
                            [styles.show_close]: sidebarVisible,
                        },
                    )}
                    type="button"
                    onClick={() => {
                        setSidebarVisible(
                            (_sidebarVisible) => !_sidebarVisible,
                        );
                    }}
                >
                    {!sidebarVisible ? <MobileMenu /> : <CloseIcon />}
                </button>
            </nav>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    currentLanguage: state.i18n.currentLanguage,
    user: state.auth.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    handleUserLogout: () => dispatch(userLogout()),
    handleSetLanguage: (language: string) => dispatch(setLanguage(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
