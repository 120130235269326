import { PageComponent } from '@pages/Type';
import classNames from 'classnames';
import Router from 'next/router';
import React, { useState } from 'react';
import styles from './RouteIndicator.module.scss';

const RouteIndicator: PageComponent = () => {
    const [loading, setLoading] = useState<boolean | null>(null);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const onLoad = () => setLoading(true);
    const onDone = () => {
        setLoading(false);
        setTimeoutId(
            setTimeout(() => {
                setTimeoutId(null);
                setLoading(null);
            }, 250),
        );
    };

    React.useEffect(() => {
        Router.events.on('routeChangeStart', onLoad);
        Router.events.on('routeChangeComplete', onDone);
        Router.events.on('routeChangeError', onDone);

        return () => {
            Router.events.off('routeChangeStart', onLoad);
            Router.events.off('routeChangeComplete', onDone);
            Router.events.off('routeChangeError', onDone);
        };
    });

    React.useEffect(
        () => () => {
            if (timeoutId) clearTimeout(timeoutId);
        },
        [timeoutId],
    );

    return (
        <div
            className={classNames(styles.indicator, {
                [styles.loading]: loading !== null && loading,
                [styles.done]: loading !== null && !loading,
            })}
        />
    );
};

export default RouteIndicator;
