import {
    ServiceData,
    serviceRequestFailedType,
    ServiceState,
    singleLoad,
} from '@root/store/services';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { AppDispatch, RootState } from '@root/store/types';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

export enum UseServiceActions {
    ANY = 'ANY',
    OS_CHANGE = 'CHANGE_OS',
    RES_CHANGE = 'CHANGE_RESOURCE',
    ROOT_CHANGE = 'CHANGE_PASSWORD',
    HOSTNAME_CHANGE = 'CHANGE_HOSTNAME',
    NS_CHANGE = 'CHANGE_NAMESERVERS',
    TRANSFER = 'TRANSFER',
}

export type UseServiceAction = {
    showLocked: boolean;
    showPlaceholder: boolean;
};

export enum UseServiceState {
    REQUEST = 'REQUEST',
    ERROR = 'ERROR',
    LOADED = 'LOADED',
}

type UseServiceLoading = {
    state: UseServiceState.REQUEST;
    isRequesting: boolean;
    error: null;
    data: undefined;
    action: undefined;
    commands: {
        refreshData: () => void;
    };
};

type UseServiceError = {
    state: UseServiceState.ERROR;
    isRequesting: boolean;
    error: serviceRequestFailedType;
    data: undefined;
    action: undefined;
    commands: {
        refreshData: () => void;
    };
};

export type UseServiceLoaded = {
    state: UseServiceState.LOADED;
    isRequesting: boolean;
    error: null;
    data: ServiceData;
    action: UseServiceAction;
    commands: {
        refreshData: () => void;
    };
};

type UseService = UseServiceLoading | UseServiceError | UseServiceLoaded;

const servicePlaceholder = (
    action: UseServiceActions,
    service: ServiceData,
): boolean => {
    if (typeof service.stateProccess === 'undefined') {
        return false;
    }
    if (service.stateProccess?.type === '4') {
        return false;
    }
    if (
        action === UseServiceActions.OS_CHANGE &&
        service.stateProccess?.type === '5'
    ) {
        return true;
    }
    if (
        action === UseServiceActions.RES_CHANGE &&
        service.stateProccess?.type === '6'
    ) {
        return true;
    }
    if (
        action === UseServiceActions.NS_CHANGE &&
        service.stateProccess?.type === '9'
    ) {
        return true;
    }
    if (
        action === UseServiceActions.HOSTNAME_CHANGE &&
        service.stateProccess?.type === '7'
    ) {
        return true;
    }
    if (
        action === UseServiceActions.ROOT_CHANGE &&
        service.stateProccess?.type === '8'
    ) {
        return true;
    }
    return false;
};

const serviceAction = (
    action: UseServiceActions,
    service: ServiceData,
): UseServiceAction => {
    return {
        showLocked:
        service.isCarantine ? true :
            typeof service.stateProccess !== 'undefined' &&
            service.stateProccess !== null &&
            service.stateProccess.type !== 'CONSOLE',
        showPlaceholder: service.isCarantine ? false : servicePlaceholder(action, service),
    };
};

type useServiceCtx = {
    action?: UseServiceActions;
    initialOrderno?: string | null;
};

export const useService = ({
    action = UseServiceActions.ANY,
    initialOrderno = null
}: useServiceCtx = {}): UseService => {
    const router = useRouter();
    const [orderNo, setOrderNo] = useState<string | null>(initialOrderno);
    const dispatch = useDispatch<AppDispatch>();
    let service = useSelector<RootState, ServiceData | null>(
        (_state) =>
            orderNo !== null ? _state.services.data[orderNo] || null : null,
        shallowEqual,
    );
    useEffect(() => {
        if (initialOrderno === null) {
            if (router.query.id && !Array.isArray(router.query.id)) {
                setOrderNo(router.query.id);
            } else {
                setOrderNo(null);
            }
        }
    }, [initialOrderno, router]);
    const refreshData = () => {
        if (orderNo !== null) {
            dispatch(singleLoad(orderNo));
        }
    };
    useEffect(() => {
        if (
            orderNo !== null &&
            (service === null ||
                (service !== null &&
                    typeof service.extra === 'undefined' &&
                    service.request.isRequesting === false &&
                    service.request.error === null))
        ) {
            dispatch(singleLoad(orderNo));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service, orderNo]);
    const isRequesting = service?.request.isRequesting || false;
    const error = service?.request.error || null;
    if (!isRequesting && service !== null && error === null) {
        return {
            state: UseServiceState.LOADED,
            data: service,
            isRequesting,
            error,
            action: serviceAction(action, service),
            commands: {
                refreshData,
            },
        };
    }
    if (!isRequesting && error !== null) {
        return {
            state: UseServiceState.ERROR,
            data: undefined,
            isRequesting,
            error,
            action: undefined,
            commands: {
                refreshData,
            },
        };
    }
    return {
        state: UseServiceState.REQUEST,
        data: undefined,
        isRequesting,
        error: null,
        action: undefined,
        commands: {
            refreshData,
        },
    };
};

export const stateToHuman = (
    service?: ServiceData,
): JSX.Element | string | null => {
    if (!service) {
        return null;
    }
    switch (service.state) {
        case ServiceState.RUNING: {
            return (
                <Trans i18nKey="state.running" ns="uiServices">
                    Veikiantis
                </Trans>
            );
        }
        case ServiceState.STARTING: {
            return (
                <Trans i18nKey="state.starting" ns="uiServices">
                    Paleidžiamas
                </Trans>
            );
        }
        case ServiceState.STOPPING: {
            return (
                <Trans i18nKey="state.stopping" ns="uiServices">
                    Išjungiamas
                </Trans>
            );
        }
        case ServiceState.RESTARTING: {
            return (
                <Trans i18nKey="state.restarting" ns="uiServices">
                    Perkraunamas
                </Trans>
            );
        }
        case ServiceState.STOPPED:
        default: {
            return (
                <Trans i18nKey="state.stopped" ns="uiServices">
                    Neveikiantis
                </Trans>
            );
        }
    }
};
