import { PageComponent } from '@root/pages/Type';
import { RootState } from '@root/store/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { LinkProps as NextLinkProps } from 'next/link';
import { RouterLink } from '../router';

export type LinkParamsProps = {
    [k: string]: string;
};

export type LinkProps = NextLinkProps & {
    params?: LinkParamsProps;
};

const Link: PageComponent<LinkProps> = ({ children, ...props }) => {
    const currentLanguage = useSelector(
        (state: RootState) => state.i18n.currentLanguage,
    );
    return (
        <RouterLink locale={currentLanguage} {...props}>
            {Array.isArray(children) ? children[0] : children}
        </RouterLink>
    );
};

export default Link;
