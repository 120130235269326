import { PageComponent } from '@root/pages/Type';
import {
    createContext,
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import Reaptcha from 'reaptcha';
import getConfig from 'next/config';
import classnames from 'classnames';
import styles from './CaptchaProvider.module.scss';
import { useInterval } from 'react-use';
import { useDeferredPromise } from '@root/utils/hooks';

export type CaptchaContextState = {
    exec: () => Promise<string>;
    reset: () => Promise<void>;
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
};

const CaptchaContext = createContext<CaptchaContextState | null>(null);

export const useCaptcha = (): CaptchaContextState => {
    const ctx = useContext(CaptchaContext);
    if (ctx === null) {
        throw new Error(
            'useCaptcha must be inside a CaptchaProvider with a value',
        );
    }
    return ctx;
};
export const CaptchaProvider: PageComponent = ({ children }) => {
    const { publicRuntimeConfig } = getConfig();
    const recaptchaInstance = useRef<Reaptcha>(null);
    const [visible, setVisible] = useState(false);
    const { defer, deferRef } = useDeferredPromise<string>();
    const [processing, setProcessing] = useState(false);
    const [showed, setShowed] = useState(false);

    useEffect(() => {
        if (!publicRuntimeConfig.RECAPTCHA) {
            throw new Error("Require 'RECAPTCHA' key in env");
        }
        return () => {
            reset();
            cleanUp();
        };
    }, []);

    const renderRecaptcha = async () => {
        if (!recaptchaInstance.current) return;
        if (!recaptchaInstance.current.state.ready) return;
        if (recaptchaInstance.current.state.rendered) return;
        return recaptchaInstance.current.renderExplicitly();
    };

    const executeRecaptcha = async () => {
        if (!recaptchaInstance.current) return;
        if (!recaptchaInstance.current.state.ready) return;
        if (processing) return;
        await renderRecaptcha();
        setProcessing(true);
        return recaptchaInstance.current.execute();
    };

    const exec = async (): Promise<string> => {
        if (recaptchaInstance.current == null) {
            throw new Error('reCatpcha ref is null..');
        }
        const promiseDefer = defer()
        return renderRecaptcha()
            .then(() => executeRecaptcha())
            .then(() => promiseDefer.promise);
    };

    const reset = async () => {
        if (!recaptchaInstance.current) return;
        if (!recaptchaInstance.current.state.ready) return;
        await renderRecaptcha();
        return recaptchaInstance.current.reset();
    };

    const cleanUp = async () => {
        const iframes = document.querySelectorAll<HTMLElement>(
            'iframe[src*="recaptcha/api2/bframe"]',
        );
        if (iframes.length === 0) return;
        const recaptchaOverlay = iframes[0].parentNode?.parentNode;
        if (!recaptchaOverlay) return;
        (recaptchaOverlay as HTMLElement).remove();
    };

    useInterval(
        () => {
            const iframes = document.querySelectorAll<HTMLElement>(
                'iframe[src*="recaptcha/api2/bframe"]',
            );
            if (iframes.length === 0) return;
            const recaptchaOverlay = iframes[0].parentNode?.parentNode;
            if (
                processing &&
                recaptchaOverlay &&
                (recaptchaOverlay as HTMLElement).style.visibility === 'visible'
            )
                setShowed(true);
            if (
                processing &&
                recaptchaOverlay &&
                (recaptchaOverlay as HTMLElement).style.visibility ===
                    'hidden' &&
                showed
            ) {
                setShowed(false);
                setProcessing(false);
                // console.log('closed');
                deferRef && deferRef.reject(new Error('closed'));
            }
        },
        processing ? 100 : null,
    );

    const onVerify = useCallback(
        (response: string) => {
            setProcessing(false);
            deferRef && deferRef.resolve(response);
        },
        [deferRef],
    );

    const onExpire = useCallback(() => {
        setProcessing(false);
        deferRef && deferRef.reject(new Error('expired'));
    }, [deferRef]);

    const onError = useCallback(() => {
        setProcessing(false);
        deferRef && deferRef.reject(new Error('error'));
    }, [deferRef]);

    return (
        <CaptchaContext.Provider
            value={{
                exec,
                reset,
                setVisible,
                visible,
            }}
        >
            <>
                {children}
                <div
                    className={classnames(styles.wrapper, {
                        [styles.active]: visible,
                    })}
                    style={{
                        zIndex: 9999999999,
                    }}
                >
                    <Reaptcha
                        ref={recaptchaInstance}
                        sitekey={publicRuntimeConfig.RECAPTCHA}
                        size="invisible"
                        onVerify={onVerify}
                        onExpire={onExpire}
                        onError={onError}
                        explicit
                    />
                </div>
            </>
        </CaptchaContext.Provider>
    );
};

export default CaptchaContext;
