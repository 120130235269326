import { CaptchaProvider } from '@components/CaptchaProvider';
import SocketProvider from '@plugins/socket/SocketProvider';
import { PageComponent } from '@root/pages/Type';
import InitializeSocketStore from '@root/store/socket';
import classNames from 'classnames';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Router, { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import ReactNotification from 'react-notifications-component';
import GoogleAnalytics from '../GoogleAnalytics';
import { LiveHelpProvider } from '../LiveHelpContext';
import Navbar from '../Ui/Navbar';
import RouteIndicator from '../Ui/RouteIndicator';
import Sidebar from '../Ui/Sidebar';
import { LayoutProvider, useLayout } from './LayoutProvider';
import styles from './ui.module.scss';

const LiveHelpNoSSR = dynamic(() => import('../LiveHelp'), { ssr: false });

const LayoutContent: PageComponent = ({ children }) => {
    const { sidebarVisible } = useLayout();
    return (
        <div
            className={classNames('content', styles.content, {
                [styles.overflowHide]: sidebarVisible,
            })}
        >
            <div
                className={classNames('container', styles.container, {
                    [styles.hidden]: sidebarVisible,
                })}
            >
                {children}
            </div>
        </div>
    );
};

const Layout: PageComponent = ({ children }) => {
    // TODO: Add default title translate.

    const { publicRuntimeConfig } = getConfig() || { publicRuntimeConfig: {} };

    const routerHook = useRouter();

    const [nextRoute, setNextRoute] = useState<string>(
        (routerHook.query.nextRoute as string) || '',
    );

    useEffect(() => {
        const routeChangeDone = () => {
            setNextRoute((Router.query.nextRoute as string) || '');
        };
        Router.events.on('routeChangeComplete', routeChangeDone);
        routeChangeDone();

        return () => {
            Router.events.off('routeChangeComplete', routeChangeDone);
        };
    }, []);

    const getServiceId = useCallback((): string => {
        if (routerHook.query.id) {
            return routerHook.query.id as string;
        }
        return '-';
    }, [routerHook.query]);

    return (
        <>

            <LiveHelpProvider>
                <SocketProvider>
                    <InitializeSocketStore>
                        <CaptchaProvider>
                            <LayoutProvider>
                                <Navbar />
                                <RouteIndicator />
                                <ReactNotification />
                                <div
                                    className={`container-fluid ${styles['container-fluid']}`}
                                >
                                    <Sidebar
                                        nextRoute={nextRoute}
                                        serviceId={getServiceId()}
                                    />
                                    <LayoutContent>{children}</LayoutContent>
                                </div>
                            </LayoutProvider>
                        </CaptchaProvider>
                    </InitializeSocketStore>
                </SocketProvider>
                <LiveHelpNoSSR />
            </LiveHelpProvider>
            {publicRuntimeConfig.GOOGLE_ANALYTICS ? (
                <GoogleAnalytics ga_id={publicRuntimeConfig.GOOGLE_ANALYTICS} />
            ) : null}
        </>
    );
};

Layout.displayName = 'UILayout';

export default Layout;
