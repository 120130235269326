import { useEffect, useRef } from 'react';
import { useSubscribeType } from './types';
import useSocket from './useSocket';

const useSubscribe: useSubscribeType = (room, callback) => {
    const savedCallback = useRef(callback);
    const socket = useSocket();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        // console.log('useSubscribe', room);
        if (!room) return;
        let subscribed = false;
        if (socket.connected) {
            socket
                .subscribe(room, savedCallback.current)
                .then(() => {
                    // console.log('useSubscribe ok', room);
                    subscribed = true;
                })
                .catch(() => {
                    // console.log('useSubscribe failed', room);
                    subscribed = false;
                });
        }
        const cleanup = () => {
            // console.log('useSubscribe cleanup', room);
            if (subscribed) {
                // console.log('useSubscribe cleanup connected', room);
                socket
                    .unsubscribe(room)
                    .then(() => {
                        // console.log('useSubscribe cleanup ok', room);
                        subscribed = false;
                    })
                    .catch(() => {
                        // console.log('useSubscribe cleanup failed', room);
                        subscribed = false;
                    });
            }
        };
        return cleanup;
    }, [socket.connected, room]);
};

export default useSubscribe;
