import { store } from 'react-notifications-component';
import styles from './Noty.module.scss';
import CheckIcon from '@static/img/icons/check.svg';
import CloseIcon from '@static/img/icons/close.svg';

type NotyContext = {
    title: string;
    message: string;
    type?: 'success' | 'ok' | 'danger' | 'error';
    duration?: number;
};

type NotyType = (context: NotyContext) => void;

const Noty: NotyType = ({
    title,
    message,
    type = 'success',
    duration = 4000,
}) => {
    const trueNotyType =
        type === 'success' || type === 'ok' ? 'success' : 'danger';
    store.addNotification({
        title,
        message,
        type,
        content: (
            <div className={styles[`notification__container--${trueNotyType}`]}>
                <div className={styles['notification__container-icon']}>
                    <div
                        className={styles['notification__container-icon-inner']}
                    >
                        {trueNotyType === 'success' && <CheckIcon/>}
                        {trueNotyType !== 'success' && <CloseIcon/>}
                    </div>
                </div>
                <div className={styles.notification__container}>
                    <div className={styles.notification__title}>{title}</div>
                    <p className={styles.notification__message}>{message}</p>
                </div>
            </div>
        ),
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration,
        },
    });
};

export default Noty;
