import { RefObject, useEffect } from 'react';

const useOnClickOutside = <T extends RefObject<HTMLElement>>(
    ref: T,
    handler: (event: TouchEvent | MouseEvent) => void,
): void => {
    useEffect(() => {
        const listener = (event: TouchEvent | MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!ref.current || ref.current.contains(target)) {
                return;
            }

            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
};

export default useOnClickOutside;
